import React from "react";
import NavBar from "./../../../common/navbar/NavBar";
import PawTrackerDashboardList from "./PawTrackerDashboardList";
import PawTrackerDashboardTimeline from "./PawTrackerDashboardTimeline";

export default function PawTrackerDashboard({ data }) {
  const { customer, pet, petTimeline } = data.data;
  return (
    <>
      <NavBar isFromPawTracker={true} />
      <div className="ptDashboard">
        <div className="ptdashboard-wrapper">
          <div className="pt-dashboard-details-wrapper">
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  {customer.firstName} {customer.lastname}
                </div>
              </div>
              <PawTrackerDashboardList
                first={true}
                label={"Mobile"}
                info={customer.mobilenumber}
                img={"smartphone"}
              />
              <PawTrackerDashboardList
                label={"Email"}
                info={customer.email}
                img={"email"}
                last={true}
              />
              <div className="ptdashboard-customer-footer-wrapper">
                <div>
                  <div className="ptdashboard-customer-footer-title">
                    {customer.petCount}
                  </div>
                  <div className="ptdashboard-customer-footer-subtitle">
                    Pet(s)
                  </div>
                </div>
              </div>
            </div>
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  <span className="ptdashboard-customer-header-label-title1">
                    {pet.name}
                  </span>
                  <span className="ptdashboard-customer-header-label-title2">
                    {pet.breed}
                  </span>
                </div>
                <div className="ptdashboard-customer-header-subtitle">
                  {petTimeline.length != 0 && petTimeline.at(-1).status}
                </div>
              </div>

              <PawTrackerDashboardList
                first={true}
                label={"Type"}
                info={pet.type}
                img={"type"}
              />
              <PawTrackerDashboardList
                label={"Color"}
                info={pet.color}
                img={"color"}
              />
              <PawTrackerDashboardList
                label={"Weight"}
                info={pet.weight}
                img={"weight"}
              />
              <PawTrackerDashboardList
                label={"Gender"}
                info={pet.gender}
                img={"gender"}
              />
              <PawTrackerDashboardList
                label={"Birthdate"}
                info={pet.birthdate}
                img={"birthday"}
                last={true}
              />
            </div>
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  <span className="ptdashboard-customer-header-label-title1">
                    Passing Details
                  </span>
                </div>
              </div>

              <PawTrackerDashboardList
                label={"Veterinary"}
                info={pet.veterinary}
                img={"vet"}
                first={true}
              />
              <PawTrackerDashboardList
                label={"Passing Date"}
                info={pet.deathDate}
                img={"deathdate"}
              />
              <PawTrackerDashboardList
                label={"Passing Cause"}
                info={pet.deathCause}
                img={"cause"}
                last={true}
              />
            </div>
          </div>
          <div className="ptdashboard-timeline-wrapper">
            <PawTrackerDashboardTimeline data={data} petName={pet.name} />
          </div>
        </div>
      </div>
    </>
  );
}
