import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

export default function PawTrackerDashboardTimelineElement({
  date,
  label,
  petName,
}) {
  const RenderDescription = (label) => {
    let lblLower = label.toLowerCase();

    if (lblLower == "created")
      return `${petName}'s now has a special place in our database, marking the beginning of their tribute.`;

    if (lblLower == "viewing")
      return `Step into our viewing room to see ${petName}, lovingly prepared for your farewell. `;

    if (lblLower == "transport")
      return `${petName}'s is now being gently transported in our facility.`;

    if (lblLower == "processing")
      return `During this phase, ${petName} is in our care, undergoing a respectful cremation process to honor their memory.`;

    if (lblLower == "pickup")
      return `We'll assist you in collecting ${petName}, as their journey with us comes full circle.`;

    if (lblLower == "delivered")
      return `${petName} is now back in your care, marking the completion of our service with heartfelt respect.`;

    if (lblLower == "queue")
      return `${petName} is currently in the queue for cremation, awaiting their turn for a respectful and heartfelt farewell.`;

    return "";
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date={date}
      iconStyle={{
        background: "rgb(228, 205, 185)",
        color: "#fff",
      }}
    >
      <h3 className="vertical-timeline-element-title">{label}</h3>
      <p className="vertical-timeline-element-description">
        {RenderDescription(label)}
      </p>
    </VerticalTimelineElement>
  );
}
